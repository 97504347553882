/*
 * @Author: dongjia
 * @Date: 2021-12-13 16:35:36
 * @LastEditTime: 2021-12-14 17:21:24
 * @LastEditors: aleaner
 * @Description: 概览统计设置
 * @FilePath: \organization-admin\api\data-stats\survey-edit.js
 * 怎么可能会有bug！！！
 */
import api from '@/base/utils/request';

//获取概况基本配置项信息
export const userStatConfig = data => {
  return api({
    url: "/admin/cyc/stat/getUserStatConfig",
    method: "post",
    data
  })
}

// 保存编辑配置信息
export const saveUserStatConfig = data => {
  return api({
    url: "/admin/cyc/stat/setUserStatConfig",
    method: "post",
    data
  })
}