<!--
 * @Author: dongjia
 * @Date: 2021-12-13 16:21:48
 * @LastEditTime: 2021-12-21 14:07:04
 * @LastEditors: aleaner
 * @Description: 概览统计配置编辑
 * @FilePath: \organization-admin\views\data-stats\survey-edit.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="subscribe-message" v-loading="loading">
    <reminder title="温馨提示" :textArr="tips" :hrefConfig="tipsConfig"></reminder>
    <el-card style="margin-bottom: 20px;">
      <div class="flex-col flex-center" style="height: 80px">
        <p style="font-size: 20px;">{{count}}</p>
        <p style="font-size: 14px;">已开启</p>
      </div>
    </el-card>
    <el-table :data="tbody" class="thead-light" stripe style="width: 100%">
      <el-table-column type="index" label="序号" :width="80"></el-table-column>
      <el-table-column v-for="(th, thIndex) in thead" :key="th.prop" :label="th.label" show-overflow-tooltip
        :width="th.width" :min-width="th.minWidth" :sortable="th.sortable">
        <template slot-scope="{row}">
          <div v-if="th.prop === 'figure'" class="figure">
            <div class="row-value" v-if="row[th.prop]">{{figure_text[row[th.prop]]}}</div>
            <div class="change-btn" @click="handleStatusChange(0,row)">{{row[th.prop]?'更改':'请选择类型'}}</div>
          </div>
          <span v-else>
            {{row[th.prop] | placeholder}}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="状态" width="150">
        <template slot-scope="{row,$index}">
          <el-switch v-model="row.is_checked" @change="handleStatusChange(1,row)" :active-value="1"
            :inactive-value="0"></el-switch>
        </template>
      </el-table-column>
    </el-table>
    <FixedActionBar>
      <el-button @click="goBack">返回</el-button>
      <el-button type="primary" @click="HandleSave" :loading="saveLoading">保存</el-button>
    </FixedActionBar>
    <FigureTypeSelector v-model="openTypeSelector" :figure="current_row.figure"
      :figureSelected.sync="current_row.figure" @cancel="cancelSelect" />
  </div>
</template>

<script>
import Reminder from "../../../common/components/Reminder";
import FigureTypeSelector from "../../components/DataStats/FigureTypeSelector";
import FixedActionBar from "@/base/layout/FixedActionBar";
import {
  userStatConfig,
  saveUserStatConfig,
} from "../../api/data-stats/survey-edit";

export default {
  components: { Reminder, FigureTypeSelector, FixedActionBar },
  data() {
    return {
      loading: false,
      saveLoading: false,
      tips: [
        "前往配置会员资料（目前仅支持统计【基础组件 - 单项选择】）",
        "【资料名称】被删除后将不再显示",
        "【资料名称】被修改后需重新打开统计",
      ],
      tipsConfig: {
        text: "前往配置会员资料",
        url:
          window.location.origin + "/admin/organize/org-frame/add/form-page/0/user",
      },
      thead: [
        { label: "资料名称", prop: "label", minWidth: 100 },
        { label: "图标类型", prop: "figure", minWidth: 200 },
      ],
      tbody: [],
      // 统计图形文案配置
      figure_text: {
        bar: "柱形图",
        line: "折线图",
        pie: "饼图",
        radar: "雷达图",
        scatter: "散点图",
      },
      current_row: {},
      openTypeSelector: false,
    };
  },
  created() {
    this.getList();
  },
  computed: {
    count() {
      return this.tbody.filter((el) => el.is_checked === 1).length;
    },
  },
  methods: {
    getList() {
      this.loading = true;
      userStatConfig()
        .then((res) => {
          const { data } = res;
          this.tbody = data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 保存配置
    HandleSave() {
      if (this.tbody.length === 0) {
        this.$msgbox
          .confirm(
            "请先前往配置会员资料（目前仅支持统计【基础组件 - 单项选择】）",
            "提示",
            {
              type: "info",
              confirmButtonText: "前往",
            }
          )
          .then((res) => {
            let routerData = this.$router.resolve({
              name: "FormPage",
              params: { id: 0 },
            });
            window.open(routerData.href, "_blank");
          })
          .catch((err) => {});
        return;
      }
      this.saveLoading = true;
      saveUserStatConfig({ config: this.tbody })
        .then((res) => {
          this.$message.success(res.msg);
          this.saveLoading = false;
          setTimeout(() => {
            this.goBack();
          }, 1000);
        })
        .catch((err) => {
          this.saveLoading = false;
        });
    },
    // 返回
    goBack() {
      this.$router.push({ name: "CycUserStat" });
    },
    handleStatusChange(type, row) {
      this.current_row = row;
      this.openTypeSelector =
        (type === 1 && !row.figure && row.is_checked === 1) || type === 0;
    },
    // 取消选择图表类型
    cancelSelect() {
      if (!this.current_row.figure) {
        this.current_row.is_checked = 0;
      }
    },
  },
};
</script>

<style lang="scss">
.subscribe-message {
  .el-card {
    width: 390px;
  }
}

.popover-table {
  .td-title {
    white-space: nowrap;
    width: 75px;
  }

  td {
    vertical-align: top;
    padding: 10px 0;
  }
}

.figure {
  display: flex;
  align-items: center;
  .row-value {
    margin-right: 10px;
  }
  .change-btn {
    font-size: 14px;
    color: $primary;
    cursor: pointer;
  }
}
</style>
